<template>
  <div class="root">
    <van-cell-group title=" " :class="form.pass_risk_tropism ? '':'hidden'">
      <div class="image">
        <van-image
          width="80px"
          height="80px"
          fit="contain"
          :src="form.pass_risk_tropism === '是' ? success_url:fail_url"
        />
        <div class="info">
          {{ form.pass_risk_tropism === '是' ? '恭喜，您通过了投资风险评估！' : '抱歉，您未通过投资风险评估！'}}
        </div>
      </div>
    </van-cell-group>
    <van-cell-group title="">
      <div class="content" v-if="form.pass_risk_tropism === '是'">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          投资风险取向: {{form.risk_tropism_assess}}
        </div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;
          说明: {{risk_tropism_assess_map[form.risk_tropism_assess]}}
        </div>
        <br/>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          匹配的产品风险等级：{{form.risk_tropism_match}}
        </div>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          说明：{{risk_tropism_match_map[form.risk_tropism_match]}}
        </div>
      </div>
      <div class="content" v-else-if="form.risk_tropism_assess_and_self_assess_match==='是'">
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          投资风险取向: {{form.risk_tropism_assess}}
        </div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;
          您的投资风险评级不足以交易蜂投证券提供的所有投资产品。
        </div>
      </div>
      <div class="content" v-else>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          您所选择的投资风险评级与根据您的选择算出来的评级不一致，请确认是否填写正确或联系蜂投证券的客服以便进一步了解您的需求。
        </div>
      </div>
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn"
        @click="next" :disabled="form.pass_risk_tropism !== '是'">
        {{ isYearlyRisk ? '提交' : '下一步（风险结果确认）'}}
      </van-button>
      <van-button class="btn" @click="before">
        重新评估
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, Field, Image as VanImage,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  computed,
  reactive,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const success_url = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/evaluation_sccess.png';
const fail_url = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/evaluation_failed.png';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      risk_tropism_assess_map: {
        保守: '倾向于价值下跌风险低的投资。您较关心保存投资价值多于获取资本回报。',
        轻度保守: '您倾向于价值下跌风险较低的投资。然而，您认同为了取得较高回报，必须面对一些风险，并准备承受投资出现一些价格波动。',
        均衡: '您愿意适度投资于增长型投资项目，同时知道它们的价格容易波动。您可以承受一些价格波动，但倾向避免大幅或频繁价格波动的可能性。',
        轻度进取: '您的风险承受能力高于平均，愿意为取得可能较高回报而接受较高的下跌风险。',
        进取: '您愿意甚至渴望为了取得可能较高的回报，而接受较高的下跌机会。',
      },
      risk_tropism_match_map: {
        低风险: '在投资期内损失资本的机率最低；预期回报波幅亦低。适合的投资者类别：一般愿意接受较低回报，借以尽量降低损失资本的机率。从损失及回报的波幅来看，投资者损失资本的风险相对轻微。',
        低至中等风险: '在投资期内损失资本的机率低；预期回报波幅中等偏低。适合的投资者类别：愿意承受的风险水平高于“低风险”投资。与风险较高的投资比较，投资者的资本相对不易受市场波动所影响，但有时可能会出现明显的损失。',
        中等风险: '在投资期内损失资本的机率属中等；预期回报波幅亦为中等。适合的投资者类别：愿意承受的风险水平高于保守型投资者，但似乎无意承受长期回报显著高于通胀率所附带的短期风险。',
        中等至高风险: '在投资期内损失资本的机率中等偏高；预期回报波幅中等偏高。适合的投资者类别：可承受较高的风险水平。投资者的资本不获保证，而且可能不时波动。偶尔可能录得大幅损失。',
        高风险: '在投资期内损失资本的机率高；预期回报波幅亦高。适合的投资者类别：可承受高风险水平。投资者的资本不 获保证，而且可能显著波动。损失幅度可能相当大。对短期损失尤其敏感的保守型投资者应限制其对这类投资 的持仓比重。基金风险评级将每半年进行检讨。',
      },
    };
  },
  methods: {
    async next() {
      if (this.isAddAccount) await this.$api.submitLog({ type: 'getRiskTropismAddAssessResult' });
      if (this.isYearlyRisk) await this.$api.submitLog({ type: 'getYearlyRiskTropismAddAssessResult' });
      if (this.isYearlyRisk) {
        await this.$api.submitRiskTropismState();
        this.$router.push({ path: '/riskOrientation/result' });
      } else {
        this.$router.push({ path: '/riskOrientation/step_three' });
      }
    },
    before() {
      this.$router.push({ name: 'riskOrientation_step_one', params: { clear: true } });
    },
  },
  setup() {
    const form = reactive({
      risk_tropism_assess: '',
      pass_risk_tropism: '',
      risk_tropism_match: '',
      risk_tropism_assess_and_self_assess_match: '',
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    const isYearlyRisk = computed(() => store.state.user.isYearlyRisk);
    onMounted(async () => {
      store.commit('setTitleValue', '风险认知');
      if (isYearlyRisk.value) {
        store.commit('setStepsShow', false);
        await httpApi.submitLog({ type: 'getYearlyRiskTropismAddAssessResult' });
      } else if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(3/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(3/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      let httprequest = httpApi.getYearlyRiskTropismAssessResult;
      if (!isYearlyRisk.value) {
        httprequest = isAddAccount.value
          ? httpAddApi.getRiskTropismAssessResult : httpApi.getRiskOrientationStepTwo;
      }
      const result = await httprequest();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item] !== undefined) {
          form[item] = result.data[item];
        }
      });
    });
    return {
      isAddAccount,
      isYearlyRisk,
      success_url,
      fail_url,
      form,
    };
  },
};
</script>

<style lang="less">
  .root {
    text-align: left;

    .image  {
      padding-top: @space-2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info {
        font-size: @font-size-2;
        padding: @space-2;
      }
    }
    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }

    .title {
      text-align: center;
    }

    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }

    .hidden {
      visibility: hidden;
    }
  }
</style>
